<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <FormCouponDetail
        :form="form"
        :v="$v"
        :chkValid="chkValid"
        :getPercent="getPercent"
        :chkAvailability="chkAvailability"
        :availabilityList="availabilityList"
        :id="id"
        :multiplyPointIcon="multiplyPointIcon"
        @getPercent="(val) => (getPercent = val)"
      />

      <div class="mt-3 panelborder">
        <div class="title-tabs">D-Store Integration</div>
        <div class="p-3">
          <b-row>
            <b-col xs sm="12" md lg xl="6">
              <label class="col-form-label"
                >Participating Products (in D-Store system)</label
              >
              <b-form-group>
                <b-row>
                  <b-col>
                    <b-form-radio
                      v-model="chkProduct"
                      class="text-detail"
                      :value="0"
                      :disabled="this.allUnSelected ? true : false"
                    >
                      All
                    </b-form-radio>
                  </b-col>
                  <b-col>
                    <b-form-radio
                      v-model="chkProduct"
                      class="text-detail text-nowrap"
                      :value="1"
                      :disabled="this.allUnSelected ? true : false"
                    >
                      Select a Product List
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Select Product -->
          <div v-if="chkProduct == 1">
            <div>
              <b-button
                class="btn-select-branch"
                @click.prevent="selectProduct"
              >
                Select Product
                <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
              </b-button>
              <!-- <div class="break"></div> -->
              <span class="branch-select">
                (Selected Product
                {{ this.countProductSelect.length }} List)</span
              >
            </div>
            <SelectProductList
              :fieldProductSelect="fieldProductSelect"
              :items="productList"
              :rows="rows"
              :filter="filter"
              :id="id"
              @deleteSelectProduct="deleteSelectProduct"
            />
          </div>
          <!-- Branch -->
          <div>
            <label class="col-form-label">Participating Branches</label>
            <div v-if="branchList && branchList.length > 0">
              <ModalSelectBranch
                :list="branchList"
                @deleteBranch="(val) => (deleteBranchArry = val)"
                @selectBranch="selectBranch"
                text="Select Branch"
                :id="id"
                :BranchSelectList="form.BranchSelect"
                :valid="chkValid"
              />
              <div v-if="errorMessageBranch" class="input-error">
                Please select value.
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAction @submit="checkForm" routePath="/coupon" />
    </div>
    <ModalSelectProduct
      ref="modalSelectProduct"
      :filter="filter"
      @product="selectProductFunc"
      @delete="(val) => (this.dataDeleteProduct = val)"
      @searchProduct="searchProduct"
      :columns="columns"
      :productLists="productListItems"
      :productListSelect="dataSelectProduct"
      :rows="rows"
      :id="id"
      :valid="chkValid"
      :isLoading="isLoadingProduct"
      @filterpage="filterPage"
      @searchCategory="searchCategory"
      :list_ah1="list_ah1"
      :list_ah2="list_ah2"
      :list_ah3="list_ah3"
      :list_ah4="list_ah4"
      @getProductAgain="getProductAgain"
    />
    <ModalConfirm ref="modalConfirm" :text="respMsg" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlertError from "@/components/modal/ModalAlertError";
import ModalSelectProduct from "@/components/coupon/detail/ModalSelectProduct";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import ModalConfirm from "@/components/coupon/modal/ModalConfirm";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[0-9A-Z-]*$/);
import SelectProductList from "@/components/coupon/detail/SelectProductList";
import FormCouponDetail from "@/components/coupon/detail//FormCouponDetail";
export default {
  components: {
    ModalSelectBranch,
    ModalConfirm,
    ModalAlertError,
    OtherLoading,
    ModalSelectProduct,
    SelectProductList,
    FormCouponDetail,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    form: {
      required: true,
      type: Object,
    },
    couponObj: {
      required: true,
      type: Object,
    },
    result: {
      required: false,
    },
  },
  data() {
    return {
      isAccept: false,
      chkAvailability: false,
      selectedPromotion: "",
      showProduct: false,
      countBranch: 0,
      chkProduct: 0,
      couponDetail: {},
      respMsg: "",
      errorMessageBranch: false,
      isLoading: true,
      branchList: [],
      errorDiscount: false,
      chkValid: 0,
      errorMsg: "",
      countIdUpdate: [],
      filter: {
        select_product: [],
        get_product: {
          start: 0,
          length: 5,
          search: "",
          ah1: "",
          ah2: "",
          ah3: "",
          ah4: "",
        },
      },
      list: [],
      columns: [
        {
          label: "",
          key: "is_check",
        },
        {
          label: "Image",
          key: "imge_url",
          class: "w-200",
        },
        {
          label: "Name/Detail",
          class: "w-200",
          key: "name",
        },
        {
          label: "SKU",
          key: "article_no",
        },
        {
          label: "Price",
          key: "price",
          class: "w-100px",
        },
      ],
      rows: 0,
      chkValProductSelect: false,
      countProductSelect: [],
      productDelete: [],
      isLoadingProduct: true,
      getPercent: true,
      dataSelectProduct: [],
      dataDeleteProduct: [],
      errorPercentMore100: false,
      deleteBranchArry: [],
      allUnSelected: false,
      modalMessage: "",
      fieldProductSelect: [
        {
          label: "Image",
          key: "imge_url",
          class: "w-200",
        },
        {
          label: "Name/Detail",
          key: "name",
          class: "w-200",
        },
        {
          label: "SKU",
          key: "article_no",
        },
        {
          label: "Price",
          class: "w-100px",
          key: "price",
        },
        {
          label: "",
          key: "action",
        },
      ],
      productListItems: [],
      productList: [],
      list_ah1: [],
      list_ah2: [],
      list_ah3: [],
      list_ah4: [],
      availabilityList: [
        { value: 0, text: "Personalized" },
        { value: 1, text: "Publish" },
      ],
      multiplyPointIcon: true,
    };
  },
  async created() {
    if (this.id != 0) {
      await this.getCouponDetail();
      this.form.allow_other_promotion =
        this.form.allow_other_promotion == 0 ? true : false;
    } else {
      await this.getBranchList();
      await this.getProductList();
    }
  },
  methods: {
    getProductAgain(val) {
      this.filter.select_product = val;
      this.getProductList("getproduct", val);
    },
    selectProductFunc(val) {
      const product = this.productList.filter((el) =>
        val.productItemSelect.includes(el.id)
      );

      const result = [];
      const map = new Map();
      for (const item of val.productItemSelect) {
        if (!map.has(item.id)) {
          map.set(item.id, true); // set any value to Map
          result.push({
            ah_no: item.ah_no,
            article_no: item.article_no,
            article_type: item.article_type,
            barcode: item.barcode,
            description: item.description,
            id: item.id,
            imge_url: item.imageUrl,
            is_check: item.is_check,
            marked: item.marked,
            name: item.name,
            ph_no: item.ph_no,
            point: item.point,
            price: item.price,
          });
        }
      }
      this.dataSelectProduct = result.map((el) => el.id);
      this.productList = result;
      this.countProductSelect = result;
    },
    redirectCoupon() {
      window.location.href = "/coupon";
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    showBranch() {
      this.$refs.ModalSelectBranch.show();
    },
    searchCategory(value) {
      this.filter = value.searchCategory;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...value.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    searchProduct(val) {
      this.filter.get_product.search = val.filter;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...val.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    filterPage(val) {
      this.filter.get_product.start = val.page;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...val.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    async getProductList(callFunc, value) {
      this.isLoadingProduct = true;
      if (callFunc === "first") {
        this.filter.select_product = this.countProductSelect;
      } else if (callFunc === "getproduct") {
        this.filter.select_product = Array.from(new Set(value));
      } else {
        this.filter.select_product = Array.from(
          new Set(this.dataSelectProduct)
        );
      }
      this.form.productSelect = Array.from(new Set(this.dataSelectProduct));
      // this.filter.delete_product = this.dataDeleteProduct;
      this.form.productDelete = this.dataDeleteProduct;
      let data = {
        id_group_product: 0,
        form: this.filter,
      };
      await this.$store.dispatch("getCouponProductList", data);
      let dataResponse = this.$store.state.coupon.couponProductList;
      if (dataResponse.result == 1) {
        this.isLoadingProduct = false;
        this.productListItems = dataResponse.detail.product_list;

        this.list_ah1 = dataResponse.detail.list_ah1
          ? dataResponse.detail.list_ah1
          : [];
        this.list_ah2 = dataResponse.detail.list_ah2
          ? dataResponse.detail.list_ah2
          : [];
        this.list_ah3 = dataResponse.detail.list_ah3
          ? dataResponse.detail.list_ah3
          : [];
        this.list_ah4 = dataResponse.detail.list_ah4
          ? dataResponse.detail.list_ah4
          : [];
        this.rows = dataResponse.detail.total_product;
      } else {
        this.isLoadingProduct = false;
      }
    },
    async checkForm() {
      try {
        this.errorMessageBranch = false;

        if (this.id === "0") {
          if (this.form.BranchSelect.length === 0) {
            this.errorMessageBranch = true;
          } else {
            this.errorMessageBranch = false;
          }
        }
        this.form.promotion_discount_type_id = this.getPercent ? 1 : 2;
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }

        if (this.errorMessageBranch) {
          return;
        }

        this.$bus.$emit("showLoading");
        if (this.chkProduct == 0) {
          this.form.productSelect = [];
        } else {
          this.form.productSelect = this.dataSelectProduct;
          this.form.productDelete = this.dataDeleteProduct;
        }
        if (this.form.allow_other_promotion) {
          this.form.allow_other_promotion = 0;
        } else {
          this.form.allow_other_promotion = 1;
        }

        if (this.id === "0") {
          this.form.start_datetime =
            this.form.valid_type_id == 2 && this.form.availability != 1
              ? this.$moment().format()
              : this.form.start_datetime;
          this.form.end_datetime =
            this.form.valid_type_id == 2 && this.form.availability != 1
              ? this.$moment().format()
              : this.form.end_datetime;
          await this.$store.dispatch("createCoupon", this.form);
          let data = this.$store.state.coupon.responseCreateCoupon;
          if (data.result == 1) {
            this.$bus.$emit("hideLoading");
            this.successAlert().then(() => {
              this.$router.push("/coupon");
            });
          } else {
            this.errorAlert(data.message);
            this.$bus.$emit("hideLoading");
          }
        } else {
          this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
          let data = {
            id: this.id,
            form: {
              ...this.form,

              promotion_discount_type_id: this.getPercent ? 1 : 2,
              minimum_amount_requirement: Number(
                this.form.minimum_amount_requirement
              ),
              productSelect:
                this.chkProduct === 0
                  ? []
                  : this.dataSelectProduct.length > 0
                  ? this.dataSelectProduct
                  : this.countProductSelect,
              BranchSelect: this.form.BranchSelect
                ? this.form.BranchSelect
                : this.countIdUpdate,

              multiply_point: this.form.multiply_point
                ? parseInt(this.form.multiply_point)
                : 0,
              point: this.form.point ? parseInt(this.form.point) : 0,
            },
          };
          await this.$store.dispatch("updateCouponById", data);
          let respData = this.$store.state.coupon.statusUpdateCoupon;
          if (respData.result == 1) {
            this.$bus.$emit("hideLoading");
            this.successAlert().then(() => {
              this.$router.push(`/coupon`);
            });
          } else {
            this.errorAlert(data.message);
            this.$bus.$emit("hideLoading");
          }
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error);
      }
    },
    selectBranch(val) {
      this.form.BranchSelect = val;
      this.countBranch = val.length;
    },
    async getCouponDetail() {
      this.chkValid = this.couponObj.check_valid;

      if (this.result == 1) {
        this.isLoading = false;
        this.productList = this.couponObj.product_list;
        this.chkAvailability = this.form.availability == 1 ? true : false;
        this.chkProduct = this.form.product_group_id == 1 ? 0 : 1;
        if (this.chkValid == 1) {
          if (this.form.product_group_id == 1) {
            this.allUnSelected = true;
          } else {
            this.allUnSelected = false;
          }
        }
        this.getPercent =
          this.form.promotion_discount_type_id == 1 ? true : false;
        let productSelect = [];
        for (const product of this.productList) {
          productSelect.push(product.id);
        }
        this.multiplyPointIcon = this.form.multiply_point > 0 ? false : true;
        this.countProductSelect = productSelect;
        this.dataSelectProduct = productSelect;
        await this.getProductList("first");
      } else {
        this.isLoading = false;
      }
      await this.getBranchList();
    },
    async getBranchList() {
      let id = 0;
      if (this.id != "0") id = this.form.branch_group_id;

      await this.$store.dispatch("getBranch", id);
      if (!this.$store.state.coupon.modalAlertLogin) {
        this.branchList = [...this.$store.state.coupon.branchList.detail];
        if (this.id != "0") {
          for (let i in this.branchList) {
            for (let j in this.branchList[i].branch) {
              if (this.branchList[i].branch[j].is_check == true) {
                this.countIdUpdate.push(this.branchList[i].branch[j].id);
              }
            }
          }
          this.form.BranchSelect = this.countIdUpdate;
          this.countBranch = this.countIdUpdate.length;
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(true);
    },
    deleteSelectProduct(val) {
      const findProductDelete = this.productList.findIndex(
        (ele) => ele.id === val
      );
      const findIdSelectDelete = this.dataSelectProduct.findIndex(
        (el) => el === val
      );
      this.productList.splice(findProductDelete, 1);
      this.dataSelectProduct.splice(findIdSelectDelete, 1);
      this.dataDeleteProduct.push(val);
    },
  },
  validations: {
    form: {
      name: { required },
      promotion_value: {
        required,
        isValidPromo: function (value) {
          if (this.getPercent === true && value > 100) return false;
          return true;
        },
      },
      prefix: {
        required: requiredIf(function () {
          return this.form.coupon_code_type == 1;
        }),
      },
      point: { required },
      availability: { required },
      multiply_point: { required },
      activated: {
        required: requiredIf(function () {
          return this.form.availability == 0;
        }),
      },
      max_quantity: { required },
      image_url: { required },
      short_description: { required },
      description: { required },
      start_datetime: {
        required: requiredIf(function () {
          return this.form.availability == 1;
        }),
      },
      coupon_code_import_name: {
        required: requiredIf(function () {
          return this.form.coupon_code_type == 2;
        }),
      },
      promotion_ref: {
        required: requiredIf(function () {
          return this.form.coupon_code_type == 2;
        }),
      },
      end_datetime: {
        required: requiredIf(function () {
          return this.form.availability == 1;
        }),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  color: #24252a;
}

.btn-save {
  background-color: var(--primary-color) !important;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 30px;
}
.btn-cancel {
  background-color: #707070;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 30px;
}
.branch-select {
  color: #000;
}
.input-error {
  color: red;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.tab-coupon {
  background-color: var(--theme-secondary-color);
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.ft-14 {
  font-size: 14px;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
</style>
